import palette from './palette';

const typography = typography => ({
  fontFamily: [
    'OVOCircular Book',
    'Futura',
    'Century Gothic',
    'sans-serif',
  ].join(','),
  fontSize: 16,
  h1: {
    fontFamily: [
      'OVOCircular Black',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(48),
    lineHeight: 1.1,
    fontWeight: 900,
  },
  h2: {
    fontFamily: [
      'OVOCircular Black',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(38),
    lineHeight: 1.1,
    fontWeight: 900,
  },
  h3: {
    fontFamily: [
      'OVOCircular Bold',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(32),
    lineHeight: 1.1,
    fontWeight: 700,
  },
  h4: {
    fontFamily: [
      'OVOCircular Bold',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(24),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  h5: {
    fontFamily: [
      'OVOCircular Bold',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(20),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  h6: {
    fontFamily: [
      'OVOCircular Bold',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(24),
    lineHeight: 1.5,
    fontWeight: 300,
  },
  body1: {
    fontFamily: [
      'OVOCircular Book',
      'Futura',
      'Century Gothic',
      'sans-serif',
    ].join(','),
    fontSize: typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontSize: typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: typography.pxToRem(14),
  },
  subtitle2: {
    fontSize: typography.pxToRem(14),
    borderBottom: `2px solid ${palette.text.green}`,
  },
});

export default typography;
