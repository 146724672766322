import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'styles/theme';
import { PreviewStoreProvider } from 'gatsby-source-prismic';

const {
  registerLinkResolver,
} = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <PreviewStoreProvider>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {element}
        </ThemeProvider>
      </AppProvider>
    </PreviewStoreProvider>
  );
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;
