const palette = {
  secondary: { main: '#979797', contrastText: '#fff' },
  background: {
    default: '#fff',
    green: '#0A9828',
    dgreen: '#006945',
    blue: '#00261c',
    dblue: '#00261c',
    xdblue: '#00261c',
    altblue: '#0779a5',
    lgrey: '#F9F9F9',
    lmgrey: '#F9F6F3',
    mgrey: '#D1D6DE',
    dgrey: '#404640',
    pink: '#044D29',
    pinkHover: '#044D29',
  },
  text: {
    primary: '#00261C',
    secondary: '#FFF',
    black: '#00261C',
    blue: '#00261c',
    green: '#0A9828',
    dgreen: '#006945',
    lgrey: '#F9F9F9',
    mgrey: '#D1D6DE',
    dgrey: '#2F3749',
    input: '#cfd5db',
    pink: '#044D29',
    pinkHover: '#044D29',
  },
  /* OVO Element */
  brand: {
    midnight: '#00261C', // replace text.primary and text.black
    midnightTint: '#808D87',
    forest: '#044D29',
    forestTint: '#86A08F',
    ovo: '#0A9828',
    ovoTint: '#95C59A',
    leaf: '#16B639',
    leafTint: '#A2D99C',
    energised: '#38E44C',
    energisedTint: '#ADEAA6',
    offwhite: '#F9F6F3',
    white: '#FFFFFF',
    black: '#000000',
  },
  neutral: {
    darkest: '#292929',
    darker: '#3F3B36',
    dark: '#635E58',
    base: '#8F8982',
    light: '#D4CFCB',
    lighter: '#EBE7E3',
    lightest: '#F9F6F3',
  },
  red: {
    darkest: '#730019',
    darker: '#AE072C',
    dark: '#D62349',
    base: '#E04A6A',
    light: '#EB748E',
    lighter: '#F5B2C1',
    lightest: '#FFEBEF',
  },
  orange: {
    darkest: '#802600',
    darker: '#C13E06',
    dark: '#E95D21',
    base: '#F17B49',
    light: '#F89B73',
    lighter: '#FFC6AD',
    lightest: '#FFEDE5',
  },
  yellow: {
    darkest: '#8F5600',
    darker: '#CB7D07',
    dark: '#E99921',
    base: '#F2AE48',
    light: '#F9C372',
    lighter: '#FFD494',
    lightest: '#FFF3E0',
  },
  green: {
    darkest: '#00261C',
    darker: '#044D29',
    dark: '#007226',
    base: '#0A9828',
    light: '#16B639',
    lighter: '#3BE44C',
    lightest: '#ADEAA6',
  },
  blue: {
    darkest: '#001166',
    darker: '#001EB2',
    dark: '#1F3BCD',
    base: '#4F66DE',
    light: '#788AE9',
    lighter: '#AEBAFB',
    lightest: '#EBEFFF',
  },
};

export default palette;
